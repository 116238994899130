:root {
  --first-color: #FF914E;
  --second-color: #07748F;
  --high-to-low: linear-gradient(to right, #07748F 0%, #72B0C0 100%);
  --low-to-high: linear-gradient(90deg, #72B0C0 0, #07748F);
}

/* ---- font family ----*/
$montserrat : 'Montserrat',
sans-serif;

/*---font size---*/
$font-1: 1rem;
$font-15: 1.5rem;
$font-17: 1.7rem;
$font-2: 2rem;
$font-25: 2.5rem;

/*---font size---*/

$font-400: 400;
$font-500: 500;
$font-600: 600;
$font-700: 700;
$font-800: 800;
$font-900: 900;

/*---- Padding size----*/
$pd-0: 0px;
$pd-5: 5px;
$pd-8: 8px;
$pd-10: 10px;
$pd-15: 15px;
$pd-20: 20px;

/*---- Margin size----*/
$auto: auto;
$mr-0: 0px;
$mr-5: 5px;
$mr-8: 8px;
$mr-10: 10px;
$mr-15: 15px;
$mr-20: 20px;
$mr-30: 30px;
$mr-40: 40px;
$mr-60: 60px;
/*--- Text Align ---*/
$center: center;
$right: right;
$left: left;

/*--- color code ---*/
$redish: #b54f3e;
$greenish: #ADA32F;
$pinkish: #d67c85;
$greenish1: #68b53e;
$voiletish: #8b3eb5;

/* Global Class name */
.MuiPaper-elevation::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
  cursor: pointer !important;
}

/* Track */
.MuiPaper-elevation::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 2px;
}

/* Track */
.MuiPaper-elevation::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 2px;
}

/* Handle */
.MuiPaper-elevation::-webkit-scrollbar-thumb {
  background: var(--second-color);
  border-radius: 2px;
  cursor: pointer !important;
}

/* Handle on hover */
.MuiPaper-elevation::-webkit-scrollbar-thumb:hover {
  background: var(--second-color);
  cursor: pointer !important;
}

.m-lr-10 {
  margin: 0px 10px;
}

.rate-calendar {
  margin-bottom: 70px;
}