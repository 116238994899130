.embed-form-super-wrapper .hs-form {
    max-width: 100%;
    margin: auto;
}

.embed-form-super-wrapper .hs-form input[type=email] {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.embed-form-super-wrapper .hs-form fieldset .hs-form-field {
    padding: 0 10px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

@media screen and (max-width:400px) {
    .embed-form-super-wrapper .hs-form fieldset.form-columns-3 .hs-form-field {
        width: 100% !important;
    }
}

.embed-form-super-wrapper {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    border-radius: 16px !important;
    -moz-border-radius: 16px !important;
    -webkit-border-radius: 16px !important;
    padding-top: 10px !important;
    margin: 0 auto;
}

.embed-form-super-wrapper .hs-form label,
.embed-form-super-wrapper .hs-form label span {
    font-size: 14px !important;
    font-display: swap;
    letter-spacing: 0px !important;
    margin-bottom: 6px;
    color: #ffffff;
}

.contactus-form .embed-form-super-wrapper .hs-form label span {
    color: #222222!important;
}

.embed-form-super-wrapper .hs-form label {
    width: 100% !important;
    float: left !important;
    text-align: left !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.embed-form-super-wrapper .hs-form .input textarea {
    height: 150px !important;
}

.embed-form-super-wrapper .hs-form .input textarea:focus,
.embed-form-super-wrapper .hs-form .input textarea:focus-visible,
.embed-form-super-wrapper .hs-form .hs-input:focus,
.embed-form-super-wrapper .hs-form .hs-input:focus-visible {
    outline: 0 !important;
    border: 1px solid #ff7a59d2 !important;
}

.embed-form-super-wrapper .hs-form input[type=email],
.embed-form-super-wrapper .hs-form input[type=text],
.embed-form-super-wrapper .hs-form .input textarea,
.embed-form-super-wrapper .hs-form .hs-input textarea,
.embed-form-super-wrapper .hs-form textarea,
.embed-form-super-wrapper .hs-form .input select,
.embed-form-super-wrapper .hs-form .hs-input select,
.embed-form-super-wrapper .hs-form select {
    text-align: left !important;
    font-display: swap;
    letter-spacing: 0px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    border-color: #e2e2e2 !important;
    color: #222222 !important;
    background-color: #f5f8fa !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-left: 10px;
    padding-right: 10px;
    border-style: solid !important;
    border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    -webkit-border-radius: 3px !important;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-top-width: 1px !important;
    border-bottom-width: 1px !important;
    border-left-width: 1px !important;
    border-right-width: 1px !important;
}

.embed-form-super-wrapper .hs-form input::-webkit-input-placeholder,
.embed-form-super-wrapper .hs-form input::-moz-placeholder,
.embed-form-super-wrapper .hs-form input:-ms-input-placeholder,
.embed-form-super-wrapper .hs-form input:-moz-placeholder {
    color: #a1a1a1 !important;
    text-align: left !important;
}

.embed-form-super-wrapper .hs-form input[type=submit] {
    font-display: swap;
    text-transform: uppercase !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    color: #fff !important;
    background: var(--first-color) !important;
    border-top-width: 2px !important;
    border-bottom-width: 2px !important;
    border-left-width: 2px !important;
    border-right-width: 2px !important;
    border-radius: 75px !important;
    border-style: solid !important;
    border-color: var(--first-color) !important;
    border-radius: 75px !important;
    -moz-border-radius: 75px !important;
    -webkit-border-radius: 75px !important;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 40px;
    padding-right: 40px;
    -webkit-transition: all .2s linear;
    -o-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -ms-transition: all .2s linear;
    transition: all .2s linear;
    -khtml-transition: all .2s linear;
}

.embed-form-super-wrapper .hs-form .hs-submit {
    display: inline-block;
    width: 100%;
}

@media screen and (max-width:767px) {

    .embed-form-super-wrapper .hs-form .form-columns-2>div.hs-form-field,
    .embed-form-super-wrapper .hs-form .form-columns-3>div.hs-form-field,
    .embed-form-super-wrapper .hs-form .form-columns-4>div.hs-form-field {
        width: 100% !important;
    }
}

.embed-form-super-wrapper .hs-richtext,
.contactus-form .embed-form-super-wrapper .hs-richtext p {
    float: left !important;
    width: 100% !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    color: #ffffff;
    font-size: 14px !important;
    font-display: swap;
    margin-bottom: 15px !important;
}

.contactus-form .embed-form-super-wrapper .hs-richtext,
.contactus-form .embed-form-super-wrapper .hs-richtext p {
    color: #222222 !important;
}

// .embed-form-super-wrapper .hs-richtext img {
//     max-width: 40%!important;
//     max-height: 40%!important;
// }
.embed-form-super-wrapper .hs-form .hs-submit .actions {
    text-align: center !important;
}

.embed-form-super-wrapper .hs-form .hs-error-msgs label,
.embed-form-super-wrapper .hs-form .hs-error-msgs label span {
    font-display: swap;
    font-weight: 500 !important;
    font-size: 12px !important;
    color: red !important;
}

.embed-form-super-wrapper .hs-form button:hover,
.embed-form-super-wrapper .hs-form input[type=button]:hover,
.embed-form-super-wrapper .hs-form input[type=submit]:hover {
    color: #ffffff !important;
}

.grecaptcha-badge {
    width: auto !important;
    height: auto !important;
    box-shadow: none !important;
    padding: 0px 10px;
}

.center-the-form form,
.center-the-form form input {
    text-align: center;
}

.fn-date-picker .pika-button {
    text-align: center !important;
}

.embed-form-super-wrapper .hs-form input[type=text],
.embed-form-super-wrapper .hs-form fieldset.form-columns-1 textarea.hs-input,
.embed-form-super-wrapper .hs-form .input textarea,
.embed-form-super-wrapper .hs-form .hs-input textarea,
.embed-form-super-wrapper .hs-form textarea {
    border: 1px solid #d6d6d6;
    width: 100%;
    padding: 9px 14px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 44px;
}

.embed-form-super-wrapper .hs-form .input .hs-input {
    height: 48px;
}

.embed-form-super-wrapper .hs-form ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
}

.embed-form-super-wrapper .hs_error_rollup ul {
    padding: 0px 10px;
}

.embed-form-super-wrapper .hs-form fieldset.form-columns-1 .hs-input {
    width: 100% !important;
}

.embed-form-super-wrapper .hs-form label {
    color: #555555;
}

.embed-form-super-wrapper .hs-form fieldset {
    max-width: 100% !important;
}

.embed-form-super-wrapper .hs-form fieldset.form-columns-3 .hs-form-field {
    width: 33.333% !important;
}

.embed-form-super-wrapper .hs-form fieldset {
    margin-bottom: 0 !important;
}

.embed-form-super-wrapper .hs-form .hs-form-field {
    margin-bottom: 15px;
}

.embed-form-super-wrapper .hs-form label {
    font-weight: normal;
    font-display: swap;
    letter-spacing: 1px;
}

.embed-form-super-wrapper .hs-form ul li {
    margin-top: 4px;
    margin-bottom: 4px !important;
    float: left;
    width: 100%;
}

.embed-form-super-wrapper .hs-form input[type=submit] {
    background: #555555;
    color: white;
    padding: 10px 45px;
    font-weight: bold;
    font-size: 20px;
    font-display: swap;
    margin-top: 5px;
}

.embed-form-super-wrapper .hs-form fieldset.form-columns-2 .hs-form-field {
    width: 50%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.embed-form-super-wrapper .hs-form label .hs-form-required {
    color: #ff7a59;
}

//conatct page style
.contactus-form .embed-form-super-wrapper .hs-richtext p {
    color: #222222;
    text-align: left;
    margin: 0px 0px 10px;
    padding-left: 0px !important;
    font-size: 14px;
    font-display: swap;
}

.contactus-form .hs-form-booleancheckbox-display p,
.contactus-form .hs-form-booleancheckbox label span {
    color: #222222;
    font-display: swap;
    font-size: 14px;
    font-weight: 500;
}

.contactus-form .hs-form-booleancheckbox label {
    margin-bottom: 0px;
}

.contactus-form .embed-form-super-wrapper {
    padding-top: 0px !important;
}

.embed-form-super-wrapper .hs-form .hs-dependent-field .hs-error-msgs label {
    position: absolute !important;
    margin-top: -14px !important;
    width: fit-content !important;
}

.embed-form-super-wrapper .hs-form .form-columns-3 .hs-error-msgs label {
    position: absolute !important;
    margin-top: 3px !important;
    width: fit-content !important;
}

.hs_region_true.hs-region_true.hs-fieldtype-checkbox.field.hs-form-field {
    label {
        span {
            font-size: 18px !important;
            font-weight: 600 !important;
        }
    }

    ul {

        li {
            width: fit-content !important;
            margin-right: 30px !important;
            margin-bottom: 4px !important;

            label {
                margin: 0px !important;
                display: flex;
                align-items: center;

                input[name=region_true] {
                    height: fit-content !important;
                    margin-top: 0px !important;
                    width: fit-content !important;
                    margin-right: 5px !important;
                }

                span {
                    margin: 0px !important;
                    font-size: 14px !important;
                    font-weight: 400 !important;
                }
            }
        }
    }
}

// media query
@media (min-width:280px) and (max-width:992px) {

    .embed-form-super-wrapper .hs-form ul li,
    .embed-form-super-wrapper .hs-form .form-columns-3 .hs-error-msgs label {
        margin-top: 0px !important;
    }

    .embed-form-super-wrapper .hs-form {

        fieldset.form-columns-3 .hs-form-field,
        fieldset.form-columns-1 .hs-form-field {
            width: 100% !important;

            .input {
                margin-right: 0px;
            }
        }
    }
}

@media (min-width:280px) and (max-width:399.5px) {
    .grecaptcha-badge {
        padding: 0px !important;

        .grecaptcha-logo {
            iframe {
                width: -webkit-fill-available !important;
            }
        }
    }
}
