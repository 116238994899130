/*--style import constant--*/
@import "./style.scss";
/*--Fonts--*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

/*--Body Style--*/
html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #343833 !important;
  min-height: 100% !important;
  font-display: swap;
}
body > #webpack-dev-server-client-overlay {
  display: none !important;
}
.modal-backdrop {
  opacity: 0.5 !important;
}
.modal{
  opacity: 1  !important;
  .modal-header{
    .modal-title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      button{
        font-size: 12px;
        font-weight: 600;
        border-radius: 50%;
        padding: 0px !important;
        width: 25px;
        height: 25px;
        &:focus{
          outline: unset;
        }
      }
    }
  }
}
.background-grey {
  background-color: #eafafa;
  padding: 55px 0px;
}

.bg-raduis {
  border-radius: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 50%);
}

.disabledbtn {
  cursor: 'not-allowed';
  opacity: 0.6 !important;
  pointer-events: none!important;
}


.loguser-ac-btn-orange {
  display: flex;
  align-items: center;
  border: 0px;
  background: var(--first-color);
  width: 120px;
  /* fallback for old browsers */
  padding: 4px 6px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  width: -moz-fit-content;
  justify-content: center;
  cursor: pointer;
  height: 32.56px;

  svg {
    margin-right: 5px;
    position: relative;
    top:3px;
  }

  &:hover,
  &:focus {
    color: rgb(0, 0, 0);
    background-color: #b8b5b5;
  }
}

.page-het {
  // height: 64.6vh;
  display: flex;
  flex-direction: column;
  min-height: 100vh !important;
  background-color: #ffffff;
}
.background-white {
  background-color: #ffffff;
}
.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.opensans-font {
  font-display: swap;
  font-size: 13px;
}

.white-background {
  background-color: #ffffff !important;
}

.pointer-events-no {
  pointer-events: none;
}
.left-0{
  left: 0 !important;
}
/*--width--*/
.w-fit, #allinfo {
  width:  fit-content !important;
}
.w-160 {
  width: 160px !important;
}
.w-145 {
  width: 175px !important;
}
.w-140 {
  width: 146px!important;
}
.w-162 {
  width: 164px!important;
}
.w-40-per{
  width: 40% !important;
}
.w-50-per {
  width: 50% !important;
}
.w-200 {
  min-width: 200px !important;
}
.w-207 {
  width: 207px !important;
}
.w-235 {
  width: 235px !important;
}
.w-d-17 {
  width: 17px !important;
  height: 17px !important;
}

/*--Height--*/

.h-590 {
  min-height: 590px !important;
}
.h-690 {
  min-height: 690px !important;
}
.h-350 {
  height: 350px;
}

.svg-resize {
  svg {
    font-size: 20px !important;
  }
}

/*--menu diabled--*/
.disabled-menu {
  cursor: not-allowed;
  opacity: 0.6;
}

.menu-tooltip {
  font-size: 12px !important;
  background: #615f5f !important;
  text-transform: initial !important;
  padding: 5px 13px !important;
}

/*--Spinner--*/
.cls-spinner {
  height: 50vh;

  .sweet-loading {
    height: 49vh !important;
  }

  p {
    text-align: center;
    font-size: 16px;
    font-weight: 500;

    span {
      font-weight: 600;
    }
  }
}

.cls-spinner-singl-event {
  height: 25vh;

  .sweet-loading {
    height: 29vh !important;
  }
}

.cls-spinner-singl-event-cal {
  height: 30vh;

  .sweet-loading {
    height: 35vh !important;
  }
}

.auth-spinner {
  height: 65vh;

  .sweet-loading {
    height: 55vh !important;
  }
}

.eventtable-loader,
.admintable-loader {
  .sweet-loading {
    height: 40vh;
  }
}

.zigzag-bg,
.zigzag-white {
  background-color: #eafafa;
  height: 350px;
  display: flex;
  align-items: center;
}

.zigzag-bg-2 {
  align-items: center;
  background-color: #eafafa;
  padding-bottom: 50px;
}

.zigzag-white {
  background-color: #fff !important;
}

/*--- text color ---*/
.txt-color {
  color: #343833 !important;
}

.txt-color-orange {
  color: var(--first-color) !important;
}
.txt-color-blue {
  color: var(--second-color) !important;
}
.greentext {
  color: #56ab2f !important;
}

.redtext {
  color: #ff4b1f !important;
}

/* HEADER H1 ,H2 --*/
.header-h2,
.header-h21 {
  font-size: $font-25;
  text-transform: uppercase;
  font-weight: $font-600;
  text-align: $center;
  margin-top: $mr-60 ;
  margin-bottom: $mr-20 ;
}

/*--text-align--*/
.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.tt-upper {
  text-transform: uppercase;
}

.text-caps {
  text-transform: capitalize;
}

.weekend-bold {
  font-weight: 700;
}

/*--Margin--*/
.m-t-0 {
  margin-top: 0px !important;
}
.m-t-4 {
  margin-top: 4px;
}
.m-t-10 {
  margin-top: 10px!important;
}
.m-t-15 {
  margin-top: 15px !important;
}
.m-b-0 {
  margin-bottom: 0px !important;
}
.m-b-12 {
  margin-bottom: 20px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-t-80 {
  margin-top: 80px !important;
}
.m-t-120 {
  margin-top: 120px !important;
}
.ht-80{
  height:80% !important;
}
.ht-40vh {
  min-height: 40vh !important;
}
.m-t-m5 {
  margin-top: -5px !important;
}
.m-r-0 {
  margin-right: 0px !important;
}
.m-l-0 {
  margin-left: 0px !important;
}
.m-l-30 {
  margin-left: 30px !important;
}
.m-auto {
  margin: auto !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}
.m-0, #m0{
  margin: 0px !important;
}
/*--Padding--*/
.p-0,
.p-00 {
  padding: 0px !important;
}

.p-40-tb {
  padding: 40px 0px;
}

.p-t-60 {
  padding-top: 60px !important;
}

.p-b-0 {
  padding-bottom: 0px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}
.p-r-15 {
  padding-right: 15px !important;
}
.p-l-0 {
  padding-left: 0px !important;
}
.p-l-10 {
  padding-left: 10px !important;
}


/*--Displays--*/
.d-flex {
  display: flex;
}

.d-data {
  display: inline-block !important;
  width: 100% !important;
}

/*--No Data Found--*/
.no-data-found {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #343833 !important;
  padding: 30px 0px;
  text-align: center !important;
  vertical-align: middle !important;
}

/*--Justify content--*/
.justify-center {
  justify-content: center !important;
}

.justify-spacebwn {
  display: flex !important;
  justify-content: space-between !important;
}
.align-center {
  align-items: center !important;
}

/* ---- flag ----*/
.country-flag {
  width: 22px;
  height: 100%;
  cursor: pointer;
}

.country-flag1 {
  width: 30px;
  height: 100%;
  cursor: pointer;
}

.flag-details {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;

  img {
    width: 30px;
  }

  .holidaydetails {
    margin-left: 5px;
    font-size: 15px;
    font-weight: 500;
  }
}

/*--mob-order--*/
.mob-order {
  display: flex;
  align-items: center;
}

/*--Button--*/
.linkbtn {
  padding: 5px 30px;
  background: var(--high-to-low);
  border-radius: 30px;
  color: #fff;
  margin-top: 30px;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    text-decoration: none;
  }
}

.table-btn {
  background-color: transparent;
  padding: 0px 10px !important;
  border: 0px !important;
  color: var(--second-color) !important;

  svg {
    display: block;
    margin: 5px auto 0px;
  }

  &:focus,
  &:active,
  &:hover {
    color: var(--second-color) !important;
    border: 0px !important;
    outline: 0 !important;
    box-shadow: none;
    text-decoration: underline;
  }
}
.err-msg, .MuiFormHelperText-root{
  font-size: 10px !important;
  color: red;
  margin-bottom: 0px;
}
.font-montserrat text {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500 !important;
  font-display: swap;
}

.price-chart-ht {
  min-height: 420px !important;

  svg {
    overflow: inherit !important;
  }
}

.tool-bar-ht {
  min-height: 500px !important;

  svg {
    overflow: inherit !important;
  }
}

.apexchart-height {
  min-height: 400px !important;

  svg {
    overflow: inherit !important;
  }
}

//google translate
.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

.goog-te-gadget .goog-te-combo {
  color: #343833 !important;
}

.skiptranslate {
  display: none;
}

/*--Background color--*/
.background-blue {
  background-color: var(--second-color) !important;
  position: sticky;
  top: 63px;
  z-index: 99;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%) !important;
  margin-bottom: 15px;
}

/*---- Graph chart focus remove ---*/
#reactgooglegraph-1,
#re-chart {
  svg {
    g {
      text,
      path,
      rect {
        &:focus {
          outline: none !important;
        }
      }
    }
  }
}

.overall-review,
.cleanliness,
.location,
.service {
  svg {
    overflow: inherit !important;
  }
}

/*--scroll style--*/
.check-list::-webkit-scrollbar,
.competitor-checkbox::-webkit-scrollbar,
.rightside-height::-webkit-scrollbar,
.channels-data-list::-webkit-scrollbar,
.overflow-selectedcomp::-webkit-scrollbar,
.competitor-checkbox2::-webkit-scrollbar, 
.source-channels::-webkit-scrollbar{
  width: 10px;
  background: transparent;
}

.check-list::-webkit-scrollbar:hover,
.competitor-checkbox::-webkit-scrollbar:hover,
.rightside-height::-webkit-scrollbar:hover,
.channels-data-list::-webkit-scrollbar:hover,
.overflow-selectedcomp::-webkit-scrollbar:hover,
.competitor-checkbox2::-webkit-scrollbar:hover,
.source-channels::-webkit-scrollbar:hover {
  width: 15px;
  background: transparent;
}

.check-list::-webkit-scrollbar-thumb,
.competitor-checkbox::-webkit-scrollbar-thumb,
.rightside-height::-webkit-scrollbar-thumb,
.channels-data-list::-webkit-scrollbar-thumb,
.overflow-selectedcomp::-webkit-scrollbar-thumb,
.competitor-checkbox2::-webkit-scrollbar-thumb,
.source-channels::-webkit-scrollbar-thumb{
  background: var(--first-color);
}

/* Features Section */
.features,
.home-features {

  // background: #f6f6f6;
  .homefeaturescontainer,
  .featurescontainer {
    .row {
      padding-left: 15px;
      padding-right: 15px;
      background-color: #eafafa;
      border-radius: 15px;
      margin-top: 70px;
    }
  }

  h2,
  h3,
  h1 {
    font-size: 25px;
    font-weight: 600;
    margin: 20px auto 45px;
  }

  p {
    font-size: 18px;
    font-weight: 500;
    margin-top: 30px;
  }

  .icon-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #E3E3E3;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 2%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    img {
      width: 40%;
    }

    .icon-2,
    .icon-3 {
      width: 60%;
    }
  }
}
.error-msg {
  font-size: 10px;
    margin-top: 2px;
    color: red;
}
/*--- Search Bar --*/
/*--Filters--*/
.filters-common {
  justify-content: space-between;
  display: flex;
  width: 100%;
}

/*-- calender-month --*/
.calender-month {
  display: flex;
  position: relative;
  justify-content: center;

  svg {
    font-size: 2.7rem;
    padding: 6px 7px;
    font-weight: 200;
    background: var(--low-to-high) !important;
    border-radius: 4px;
    margin-right: 5px;
    margin-left: 5px;
    color: #fff;
    cursor: pointer;
  }

  p {
    text-transform: uppercase;
    margin: 0 10px;
    line-height: normal;
    position: relative;
    top: 2px;
    font-size: 19px;
    font-weight: 800;
    color: #343833;
  }

  .calender-p {
    width: 178px;
    text-align: center;
  }

  .calender-p-date {
    width: 117px;
    text-align: center;
  }
}

/*--filters-style--*/
.drop-lft {
  justify-content: flex-start !important;
}

.filters-style {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-right: 10px;

  .w-inherit {
    width: inherit !important;
  }

  .dropdown-boxs {
    margin-right: 7px;

    &:last-child {
      margin-right: 0px;
    }

    select {
      border: none;
      width: 100%;
      padding: 10px 5px;
      border-radius: 3px;
      box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
      background: #fff;
      color: #343833;
      text-transform: capitalize;
      &:disabled {
        opacity: 0.85;
        background: #fff;
      }

      &:hover,
      &:focus,
      &:focus-visible,
      &:focus-within {
        outline: 0 !important;
      }

      option {
        text-transform: capitalize;
      }
    }
  }
}

/* -- btn disable --*/
.btn.disabled,
.btn[disabled] {
  opacity: 1;
}

/*--Tabs--*/
.app-tabs {
  display: flex;

  .tabs-mui {
    border: 2px solid #fff;
    height: fit-content;

    .MuiTabs-root {
      min-height: 29px;

      button.MuiTab-root {
        padding: 6px 6px;
        max-width: initial !important;
        min-width: initial !important;
        min-height: 14px;

        svg {
          width: 24px;
          height: 24px;
          padding: 3px;
          color: #fff;
        }
      }

      button.Mui-selected {
        background-color: #fff;

        svg, .fill-wt {
          color: var(--second-color) !important;
          fill: var(--second-color) !important;
        }
        
      }

      .MuiTabs-indicator {
        display: none;
      }
    }
  }
}

.download-btn {
  margin: 8px 0px 7px 12px;
  display: flex;
  align-items: center;
  svg {
    color: #fff;
    font-size: 18px;
    cursor: pointer;

    &:hover {
      color: #E3E3E3;
    }
  }
}

.rangeslider {
  margin-top: 22px;

  .rc-slider {
    .rc-slider-tooltip {
      .rc-slider-tooltip-inner {
        width: 68px;
        height: 22px;
        font-size: 11px;
      }
    }
  }
}

/*--Why-choose-this --*/
#why-choose {
  padding: 65px 0px 55px;

  h5 {
    position: relative;
    margin-bottom: 70px;
    font-size: 27px;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    color: #343833;
    margin-top: 0px;
  }

  p {
    line-height: 1.5;
    margin: 20px 0;
    font-size: 18px;
    font-weight: 400;
    color: #343833;
    text-align: center;
  }

  .card-bg {
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    border-radius: 15px;
    border: none;
    height: 200px;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    background-color: var(--second-color);
    padding: 10px;
    display: flex;
    align-items: center;

    img {
      width: 40%;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      border-radius: 0px;
      object-fit: cover;
      background-size: 100% auto;
    }
  }
}

/*--Events Calendar--*/
.day-name {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 800;
}

.eventslinks {
  display: flex;
  margin: auto;
  justify-content: center;
}

.events-links {
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  color: var(--second-color);
  cursor: pointer;

  &:hover {
    color: var(--first-color);
  }
}

.calendar {
  margin-bottom: 70px;
}

/*--Headings--*/
.page-heading {
  h1 {
    font-size: 1.75rem;
    font-weight: 700;
    color: #343833;
    margin: 8px auto 15px;

    span {
      font-size: 1.75rem;
      font-weight: 600;
    }
  }
}

.refresh-manual {
  h2 {
    font-size: 1.4rem;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

/*--Events table--*/
.eventstable,
.admintable-view,
.without-user-shop,
.ratestable-view {
  margin: 20px 0px 0px 0px;

  .MuiPaper-root {
    max-height: 100%;

    table {
      border: 2px solid #e2e2e2;
      border-radius: 5px;
      box-shadow: 0 3px 10px rgba(0, 0, 0, .2);

      thead {
        tr {
          th {
            font-size: 14px;
            font-family: $montserrat;
            font-display: swap;
            font-weight: 700;
            line-height: normal;
            padding: 10px 5px;
            background-color: var(--second-color);
            color: #fff;
            z-index: 0;
            text-align: center;
          }
        }
      }

      tbody {
        tr:nth-child(even) {
          background-color: #E3E3E3;

          &:hover {
            background-color: #eafafa;
          }
        }

        tr {
          &:hover {
            background-color: #eafafa;
          }

          td {
            font-size: 14px;
            font-family: $montserrat;
            font-display: swap;
            padding: 10px 5px;
            text-align: center;
            min-width: 130px;
            font-weight: 500;

            a {
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }

  .MuiTablePagination-root {
    margin-top: 10px;

    p {
      font-size: 14px;
    }

    .MuiInputBase-root {
      margin-right: 10px;

      .MuiSelect-select {
        font-size: 14px;
        padding-right: 8px;
      }
    }

    svg {
      position: relative;
      top: -5px;
      font-size: 2.5rem !important;
      color: #343833 !important;
    }
  }
}

/* -- Events table pagination--*/
.event-list-pagination,
.admin-list-pagination,
.without-shop-list-pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  .page-left,
  .page-right,
  .page-1 {
    display: flex;
  }

  .page-left {

    p,
    .rows-per-page {
      font-size: 14px;
      font-weight: 500;
      color: #343833;
      margin-right: 10px;
      margin-bottom: 0px;
    }

    .rows-per-page {
      text-align: center;
      margin-left: 5px;
      position: relative;
      top: -2px;

      .MuiSelect-select {
        padding: 6px 21px 1px 5px;
        font-size: 13px;
        font-family: $montserrat;
        font-display: swap;
        border: 1px solid #6b6c6a;
        margin-left: 7px;
        border-radius: 3px;
      }

      fieldset {
        border: 0px;

        legend {
          display: none;
        }
      }
    }
  }

  .page-right {
    .page-2 {

      nav {
        ul {
          li {
            button {
              font-size: 14px;
              font-weight: 500;
              color: #343833;
              font-family: $montserrat;
              font-display: swap;
              svg {
                font-size: 20px;
              }
            }
          }
        }
      }
    }

    .page-1 {
      position: relative;
      top: 3px;
      margin-right: 20px;

      svg {
        font-size: 14px;
        cursor: pointer;
      }

      .svg-1 {
        margin-right: 8px;
      }

      .svg-2 {
        margin-left: 8px;
      }

      .page-number {
        font-size: 14px;
        font-weight: 500;
        color: #343833;
      }
    }
  }
}

/*--Event Details--*/
#content-center {
  display: block;
  justify-content: center;
  margin-left: auto !important;
  margin-right: auto !important;

  .left-card {
    border: 1px solid #e6eaf1;
    border-radius: 0.4rem;
    box-shadow: 0 0 15px 1px #d8d4d4;
    margin-bottom: 0px;
    padding: 15px 15px;
    display: block !important;
    margin-top: 20px;
    position: relative;
    background-color: #fff;
    height: 450px;

    p {
      text-align: left;
    }

    .singel-event-data-list {
      padding: 0px;
      list-style: none;
      margin-bottom: 10px;

      li {
        .span-data {
          display: flex;
          align-items: center;
          margin-bottom: 13px;

          svg {
            font-size: 25px;
            margin-right: 8px;
          }

          .category-tag {
            margin-left: 15px;
            background-color: var(--first-color);
            padding: 1px 8px;
            border-radius: 30px;
            font-size: 10px;
            color: #fff;
            text-align: center;
            max-width: 50%;
            width: fit-content;
            border: none;
            cursor: unset;
          }
        }
      }

      .event-date {
        .span-data {
          background-color: var(--first-color);
          width: -moz-fit-content;
          width: fit-content;
          padding: 5px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          margin-left: auto;
          font-size: 12px;
          font-weight: 600;
          color: #fff;
          justify-content: center;
        }

        svg {
          font-size: 15px !important;
        }
      }

      .hotel-name {
        display: flex;

        .single-sub-category-tag {
          padding: 5px 15px;
          border-radius: 25px;
          width: -moz-fit-content;
          width: fit-content;
          margin-right: 15px;
          font-weight: bold;
          color: #fff;
          font-size: 15px;
          height: fit-content;
          min-height: 35px;
          border: none !important;
          line-height: 1.42857143;
        }

        .span-data {
          font-weight: 700;
          font-size: 15px;
          align-items: center;
        }
      }

      .category--title-tag {
        padding: 5px 15px;
        border-radius: 25px;
        font-size: 15px;
        width: fit-content;
        margin-right: 15px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 13px;
        display: flex;
        align-items: center;
        text-align: center;

        .evnt-name-align {
          display: flex;
          height: fit-content;
          text-align: left !important;
          align-items: center;
          line-height: 1.7;
        }
      }

      .event-name {
        .span-data {
          font-weight: 600;
          font-size: 15px;
          align-items: flex-start;

          .evnt-name-align {
            display: flex;
            align-items: flex-start;
          }
        }
      }

      .Venue {
        .span-data {
          align-items: flex-start;
          font-weight: 500;
          font-size: 14px;

          p {
            margin-bottom: 5px;
          }
        }
      }

      .Competitor-name {
        font-size: 16px;
        font-weight: 700;

        .span-data {
          font-size: 13px;
          font-weight: 500;
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }

      &:hover {
        display: block;
        text-decoration: none;
        transform: none;
      }
    }
  }
}

/* -- Welcome page first section */
.bg-img {
  padding: 0;
  background: var(--second-color);

  .section-title {
    margin-top: 55px;
    margin-bottom: 55px;

    h2,
    p,
    h1 {
      color: #fff;
      font-weight: 600;
      font-size: 32px;
      line-height: 1.42857143;
      text-transform: uppercase;
      padding-bottom: 0px;
      margin-bottom: 0px;
      text-align: center;

      svg {
        font-size: 32px;
        margin-right: 7px;
      }
      .about-info-svg{
        font-size: 40px !important;
        margin-bottom: -8px;
      }
    }
  }
}

/*--Auto complete--*/
.autocomplete-dropdown-container {
  display: block;
  width: 55%;
  position: absolute;
  z-index: 99;
  background-color: #fff !important;
  border: 1px solid black;
  max-height: 300px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  overflow: auto;
  padding: 7px 7px 7px;
  border-radius: 6px;
  margin-top: 0px;
  border: none;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.3);

  button {
    width: 100%;
    border: none;
    background-color: transparent !important;
  }

  &::-webkit-scrollbar {
    width: 1px;
  }
}

.pgae-height-fixed {
  position: relative;
  min-height: 59.2vh;
}

/*--Searchh main--*/
.search-container {
  max-height: fit-content !important;
  height: 100% !important;

  .placeholder-img {
    display: none;
  }
}
/* React Tool tip */
.competitors-tooltip{
  width: 300px !important;
}

/* Tool Tip */
.quick-graph {
  .apexcharts-tooltip.apexcharts-theme-light {
    .scroll-added-tip,
    .scroll-added-tip-list-col-2,
    .scroll-added-tip-list-col-3 {
      .compeprice-data {
        .currency-data {
          .no-of-attendees {
            margin-right: 2px;
          }
        }
      }
    }
  }
}

.price-graph,
.quick-graph,
.rates-parity {
  .apexcharts-tooltip.apexcharts-theme-light {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
    background-color: #E3E3E3 !important;
    border-radius: 4px !important;
    border: none !important;
    padding: 8px 9px 4px 9px;
    font-size: 9px !important;
    font-weight: 600;
    max-width: 700px;
    font-family: "Montserrat", sans-serif !important;
    font-display: swap;
    z-index: 98 !important;

    .tooltip-width {

      .scroll-added-tip,
      .scroll-added-tip-list-col-2,
      .scroll-added-tip-list-col-3 {
        .compeprice-data {
          .currency-data {
            min-width: 40px !important;
          }
        }
      }
    }

    .hotelnamelist {
      display: flex;
      align-items: center;
      font-size: 11px !important;
      margin-bottom: 4px;

      img {
        width: 13px;
        margin-right: 5px;
      }
    }

    .scroll-added-tip-list-col-2 {
      -webkit-column-count: 2;
      /* Chrome/Opera, Safari */
      -moz-column-count: 2;
      /* Mozilla Firefox */
      column-count: 2;
    }

    .scroll-added-tip-list-col-3 {
      -webkit-column-count: 3;
      /* Chrome/Opera, Safari */
      -moz-column-count: 3;
      /* Mozilla Firefox */
      column-count: 3;
    }

    .scroll-added-tip,
    .scroll-added-tip-list-col-2,
    .scroll-added-tip-list-col-3 {
      padding-left: 0px;
      margin-bottom: 4px;

      .compeprice-data {
        margin-bottom: 4px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        .evt-name {
          display: flex;
        }

        p {
          margin-bottom: 0px;

          img {
            width: 13px;
            margin-right: 2px;
            height: auto;
            margin-top: -3px;
          }
        }

        .txtwrap {
          max-width: 200px;
          overflow: hidden;
          white-space: nowrap;
          /* Don't forget this one */
          text-overflow: ellipsis;
          margin-right: 8px;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          white-space: normal;
        }

        .currency-data {
          // display: flex;
          // justify-content: space-between;
          min-width: 40px;
          text-align: left;
        }
      }
    }

  }

  .apexcharts-xaxistooltip {
    color: #fff !important;
    background: #222 !important;
    border: 1px solid transparent !important;
  }
}

/*apexchart legent text font */
.apexcharts-legend-series {
  display: flex;

  .apexcharts-legend-text {
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: inline-block;
    font-size: 15px !important;
    font-family: $montserrat !important;
    font-display: swap;
    max-width: 160px;
    font-weight: 500;
  }
}

.google-visualization-tooltip {
  width: 30% !important;
  border: none !important;
  border-radius: 4px !important;
  position: absolute !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
  background-color: #E3E3E3 !important;
  font-size: 10px !important;
  margin-top: 10%;
  z-index: 98 !important;
}

div.google-visualization-tooltip>ul>li>span {
  color: #343833 !important;
}

div.google-visualization-tooltip>#testing {
  padding: 0px 10px;

  h5 {
    font-size: 13px !important;
    font-weight: 700 !important;
    margin-top: 7px;
    font-family: $montserrat!important;
    font-display: swap;
  }

  h6 {
    font-size: 12px !important;
    font-weight: 500;
    font-family: $montserrat!important;
    font-display: swap;
    display: flex;
    align-items: center;
    span {
      font-weight: 700 !important;
      margin-left: 3px;
    }
  }
}

/*--selected--packages--*/
// .selected-packages {
//   border: 4px solid var(--first-color) !important;
//   margin-top: -13px !important;
// }

// .selected-packages::before {
//   width: 0;
//   height: 0;
//   border-style: solid;
//   border-width: 64px 64px 0 0;
//   border-color: var(--first-color) transparent transparent transparent;
//   position: absolute;
//   left: 19px;
//   top: -9px;
//   content: "";
// }

// .selected-packages::after {
//   color: white;
//   position: absolute;
//   left: 27px;
//   top: 0px;
//   text-shadow: 0 0 2px var(--first-color);
//   font-size: 2rem;
//   font-family: "Font Awesome 5 Free";
//   content: url("../src/assets/Images/white-tick.webp");
//   font-display: swap;
// }

// .google-ifrme {
//   iframe {
//     height: 590px;
//     width: 100%;
//     border: 2px solid rgb(92 168 251);
//     border: 5px;
//   }
// }

/*--Date picker for rates menu--*/
.date-end-to-end {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  span {
    font-weight: 700;
  }
}

.rc-slider-track {
  background-color: var(--second-color) !important;
  height: 7px !important;
  top: 3px;
}

.rc-slider-handle {
  background-color: #ffffff !important;
  border-color: var(--second-color) !important;
  opacity: 1 !important;
  width: 18px !important;
  height: 18px !important;
  top: 3px;

  &:active,
  &:focus-visible,
  &:focus {
    box-shadow: unset !important;
  }
}

.rc-slider-handle-dragging {
  box-shadow: unset !important;
}

.date-picker-app .date-picker {
  .react-bootstrap-daterangepicker-container {
    margin: $mr-0 auto;
    display: flex !important;
    justify-content: $center !important;

    button {
      padding: 8px !important;
      background-color: #fff;
      border: 1px solid rgb(205, 205, 205) !important;
      border-radius: 8px !important;
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;

      h5 {
        font-size: $font-15 !important;
        font-weight: $font-700 !important;
        margin-top: $mr-0 !important;
        margin-bottom: $mr-0 !important;
        display: flex;
        align-items: center;
        margin-bottom: 0px;

        svg {
          margin-right: 3px;
          color: #343833;
        }

        span {
          margin: 0px 10px;
        }
      }
    }
  }
}

.mob-map-p-0 {
  margin-bottom: 20px !important;
}

/*--Mapping--*/
.cursor-pointer {
  svg {
    cursor: pointer;
  }
}

.marker-btn {
  svg {
    width: 25px;
    height: 25px;
  }

  .orange-mark {
    color: var(--first-color);
  }

  .blue-mark {
    color: var(--second-color);
  }

  .yellow-mark {
    color: yellow;
  }

  .redish-mark {
    color: $redish;
  }

  .greenish-mark {
    color: $greenish ;
  }

  .pinkish-mark {
    color: $pinkish;
  }

  .greenish1-mark {
    color: $greenish1;
  }

  .voiletish-mark {
    color: $voiletish;
  }
}

.redish-bg {
  background-color: $redish !important;
}

.greenish-bg {
  background-color: $greenish !important;
}

.pinkish-bg {
  background-color: $pinkish !important;
}

.greenish1-bg {
  background-color: $greenish1 !important;
}

.voiletish-bg {
  background-color: $voiletish !important;
}

.redish-border {
  border: 2px solid $redish;
}

.greenish-border {
  border: 2px solid $greenish;
}

.pinkish-border {
  border: 2px solid $pinkish;
}

.greenish1-border {
  border: 2px solid $greenish1;
}

.voiletish-border {
  border: 2px solid $voiletish;
}

/*--No competitor found --*/
.no-competior-found {
  list-style: none;
  padding: 10px 10px 10px 10px !important;

  li {
    list-style: none !important;
    text-align: center;
    margin-top: 20px;
    font-size: 13px;
    color: var(--first-color);
  }
}

.rbc-day-bg {
  cursor: pointer;
}

/*===button custom ========*/
.btn-custom {
  text-transform: uppercase;
  text-decoration: none !important;
  color: #fff;
  background-color: var(--second-color);
  padding: 14px 80px;
  letter-spacing: 1px;
  white-space: normal;
  overflow-wrap: break-word;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: 0;
  text-decoration: none;
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
  color: #fff;
  background-image: none;
  background-color: var(--first-color);
}

/*==== contact and about ===*/
.faq {
  padding: 0px 0 70px 0;

  .faqcontainer {
    max-width: 100%;

    .row {
      background: #E3E3E3;
      display: flex;
      border-radius: 15px;

      .left-img {
        padding: 0px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 15px 0px 0px 15px;
        }
      }

      .right-content {
        margin: auto;
      }
    }

    h5,
    h3 {
      font-size: 35px;
      color: #343833;
      text-align: center;
      font-weight: 700;
    }

    p {
      line-height: 1.5;
      margin: 30px 0;
      font-size: 18px;
      font-weight: 400;
    }

    .best-rate-btn {
      background-color: var(--second-color);
      display: inline-block;
    }

    .service-desc {
      width: 85%;
      margin: 40px auto;
    }

    .btn-custom {
      background-color: var(--second-color);
    }
  }
}

/*--Map marker icons--*/
.y-1 {
  width: 14px !important;
  height: 14px !important;
}

.y-2 {
  width: 20px !important;
  height: 20px !important;
}

.y-3 {
  width: 26px !important;
  height: 26px !important;
}

.y-4 {
  width: 32px !important;
  height: 32px !important;
}

.y-5 {
  width: 38px !important;
  height: 38px !important;
}

/* -- discount price style -- */
.sp_price {
  font-size: 16px;
  text-align: right;
  font-weight: 600;
}

/*  ---- Map box tool tip --*/
#event-map,
#comptitor-map,
#single-event {
  .mapboxgl-popup-tip {
    pointer-events: auto !important;
    cursor: pointer;
  }

  .mapboxgl-popup-content {
    pointer-events: none !important;
    width: 200px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 15px 1px #646464;
    padding: 10px;

    .hr-bt {
      // border-bottom: 2px solid var(--first-color);
      padding-bottom: 4px;
      margin-bottom: 7px;
    }

    .lft-mr {
      margin-left: 15px;
    }

    .tooltip-data {
      display: block;
      align-items: flex-start;
      font-size: 11px;

      .tool-txt {
        font-weight: 600;
        margin-right: 5px;
        display: flex;
        align-items: center;
      }

      p {
        margin-bottom: 0px;
        padding: 0px;
      }
    }
  }
}

.start-weekon {
  text-align: center;
  margin: 12px 0px 5px;

  label {
    margin-right: 10px;
    margin-top: 5px;
    font-size: 15px;
  }

  button {
    border-radius: 0px;
    font-size: 14px;
    font-weight: 500;
    padding: 4px 15px;
    border: 0px;
  }

  .border-radius-week {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .border-radius-week-back {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.startweekends {
  margin: 0px 0px 15px !important;
  display: flex;
  justify-content: center;
}

.weekonactive {
  background: var(--low-to-high) !important;
  color: #fff !important;
}

.weekmonday {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.weeksunday {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.center-spinner {
  height: 50vh !important;

  .sweet-loading {
    height: 10vh !important;
  }
}

/* graph txt */
.review-graph text,
.com-set-graph-text text {
  font-size: 13px !important;
}
// Loader background disabled
.bg-hide {
  width: 100%;
  height: 100%;
  background-color: #0000;
  position: fixed;
  overflow: unset;
  top: 0;
  z-index: 99999;

  .cls-spinner {
    height: 100%;

    .sweet-loading {
      height: 100% !important;
      top: 0;
    }
  }
}

// Snackbar 
.deleteconfirmation,
.bg-orange {
  .MuiAlert-filledSuccess, .MuiAlert-standardSuccess {
    background-color: var(--first-color);
    color: #ffffff!important;
  }
}
.bg-green {
  .MuiAlert-filledSuccess, .MuiAlert-standardSuccess {
    background-color: #4caf50;
    color: #ffffff!important;
  }
}
.long-alert {
  .MuiAlert-filledSuccess, .MuiAlert-standardSuccess {
    width: 50% !important;
  }
}

.emailconfirmation,
.comptitorconfrimation {
  left: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  top: 20px !important;
  transform: none !important;
  align-items: baseline !important;

  .MuiAlert-root {
    padding: 0px 16px !important;
  }

  .MuiAlert-filledSuccess, .MuiAlert-standardSuccess {
    display: block;
    width: 25%;
  }

  .MuiAlert-icon {
    justify-content: center;
    font-size: 38px;
  }

  .MuiAlert-message {
    font-size: 15px;
    font-weight: 500;
    padding: 8px 0px 8px;

    p {
      text-align: left;
      margin-bottom: 20px;
      margin-left: 35;
    }

    hr {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}

.competitormessage,
.adminmessage {
  .adminicon {
    justify-content: space-between;
    display: flex;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 10px;

    .alert-txt {
      display: flex;
      align-items: center;
    }

    .closeicon {
      cursor: pointer;

      svg {
        font-size: 18px;
        margin-right: 0px;
      }
    }

    svg {
      font-size: 24px;
      margin-right: 5px;
    }
  }

  .MuiAlert-icon {
    display: none;
  }

  .MuiAlert-message p {
    margin-left: 30px !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    line-height: 1.6;
  }
}

.emailconfirmation-btn,
.emailconfirmation-btn2,
.emailconfirmation-btn3 {
  display: flex;
  width: 100%;
  justify-content: flex-end;

  button {
    display: flex;
    margin-left: 15px;
    margin-right: 15px;
    background-color: #fff;
    padding: 4px 15px;
    color: #000;
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:active {
      background-color: #ddd;
      outline: 0 !important;
    }

    &:last-child {
      margin-right: 0px;
    }
  }
}

.no-competitor {
  list-style: none !important;
  text-align: center !important;
  padding: 0px !important;
  position: relative;
  left: -7px;
  color: var(--first-color) !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.scroll-container {
  max-height: 420px;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--second-color);
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--second-color);
  }
}

.single-event-topbar {
  .page-heading h1 {
    margin-bottom: 7px !important;
  }
}

.mini-d-none{
  display: none !important;
}
/*--promo code--*/
.promocode-end{
  // position: absolute;
  // bottom: 0px;
  width: 100%;
  h5{
    font-size: 11px !important;
  }
}
.promo-label{
  font-size: 13px;
  font-weight: 700;
  display: none;
}
.promocode-feild{
  margin-right: 0px;
  display: flex;
  align-items:flex-start;
  .search-container-comp{
    width: 100%;
    input {
      height: 30px;
      width: 100%;
      border: 1px solid #c8c1c1;
      border-radius: 5px;
      outline: none;
      padding: 5px 10px 5px 10px;
      cursor: text;
      transition: border-radius .2s;
      background-color: #f1f3f4;
    }
    input[type="text"]{
      font-size: 11px;
      font-weight: 600;
    }
  }
  .paymentbtn{
    margin-left: 15px !important;
      padding: 7px 15px !important;
      font-size: 11px !important; 
    &:focus{
      outline: unset;
    }
  }
}
.bg-white{
  .MuiAlert-filledSuccess, .MuiAlert-standardSuccess {
    background-color: #fff !important;
    color: #000;
  }
}

.no-booking-popup{
  .MuiAlert-filledSuccess, .MuiAlert-standardSuccess{
    width: 40% !important ;
  }
}

.contactus-popup{
  align-items: center !important;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0px !important;
  .MuiAlert-filledSuccess, .MuiAlert-standardSuccess{
    width: 25% ;
  }
  .MuiAlert-message{
    p{
      text-align: center !important;
      margin-left: 0px !important;
      font-size: 17px !important;
    }
    .btn-custom{
      text-align: center;
      width: fit-content;
      background-color: var(--second-color);
      width: fit-content;
      border-radius: 5px !important;
      padding: 10px 25px;
    }
  }
}
button.btn-disabled-package {
  opacity: 0.6 !important;
  pointer-events: none;
  cursor: not-allowed !important;
}

.com-set-graph-text g path:focus {
  outline: 0 !important;
}

div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner {
  padding: 5px 0px 0px !important;
}
div#hs-eu-cookie-confirmation, .MuiTypography-root {
  font-family: 'Montserrat', sans-serif !important;
  font-display: swap;
}

div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner p {
  margin: 0 0px 4px !important;
  font-family: 'Montserrat', sans-serif !important;
  font-display: swap;
}
.div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner div#hs-en-cookie-confirmation-buttons-area{
  margin-right: 0px !important;
}

#hs-eu-close-button-container {
  margin-top: 3px !important;
}
/*-- React tour tooltip --*/
.skip-tour{
  background-color: #e1e1e1 !important;
  span[style ="position: absolute; font-family: monospace; background: var(--reactour-accent,#007aff); height: 1.875em; line-height: 2; padding-left: 0.8125em; padding-right: 0.8125em; font-size: 1em; border-radius: 1.625em; color: white; text-align: center; box-shadow: rgba(0, 0, 0, 0.3) 0px 0.25em 0.5em; top: -0.8125em; left: -0.8125em;"]{
    top: 0px !important
  }
  h5{
    font-size: 14px !important;
    font-weight: 600;
  }
  p{
    font-size: 13px !important;
  }
  .reactour__close-button{
    color: #000 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    left: unset !important;
    right: 90px !important;
    top: unset !important;
    bottom: 32px !important;
    color: var(--second-color) !important;
    width: fit-content !important;
    &::after{
      content: "Skip Tour";
    }
    svg{
      display: contents !important;
    }
  }
  button[aria-label="Go to prev step"]{
    font-weight: 600;
    font-size: 12px;
    color: var(--first-color);
    &::after{
      content: "Back";
      margin-left: 5px;
    }
    svg{
      width: 9px !important;
      margin-top: 5px !important;
      height: 9px !important;
    }
  }
  button[aria-label="Go to next step"]{
    font-weight: 600;
    font-size: 12px;
    color: var(--first-color);
    &::before{
      content: "Next";
      margin-right: 5px;
    }
    svg{
      width: 9px !important;
      margin-top: 5px !important;
      height: 9px !important;
    }
  }
  button[style="display: block; padding: 0px; border: 0px; background: none; cursor: not-allowed;"]{
    &::after, &::before, svg{
      display: none !important;
    }
  }
}

.last-skip{
  .reactour__close-button{
    right: 25px !important;
    &::after{
      content: "Close";
    }
  }
  button[aria-label="Go to next step"]{
    display: none !important;
  }
}
.info-icon{
  cursor: pointer;
  font-size: 1.8rem !important;
  margin-bottom: -4px ;
}
.fill-wt{
  fill: #fff;
}
.fill-blk{
  fill: #343833;
}

/*--Media Query--*/
@media (min-width: 280px) and (max-width: 320px) {

  .event-list-pagination,
  .admin-list-pagination,
  .without-shop-list-pagination {
    .rows-per-page {
      margin-right: 0px !important;
    }

    .page-left,
    .page-right {
      display: block !important;
      justify-content: center !important;
    }
  }

  .calender-month {
    .calender-p-date {
      width: 150px;
      font-size: 14px;
    }

    .calender-p {
      width: 170px;
      font-size: 14px;
    }
  }

  .header-h2 {
    margin-top: 30px;
  }
}

@media (max-width: 399.5px) {
  .background-blue {
    top: 58px;
  }

  .mob-center-filter {
    .filters-common {
      display: block;

      .app-tabs {
        justify-content: flex-end;
      }
    }

    .reviews-flt-bar {
      .w-145 {
        width: 100% !important;
      }
      .w-140 {
        width: 100% !important;
      }
      .w-162 {
        width: 100% !important;
      }
    }
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .emailconfirmation .MuiAlert-filledSuccess, .emailconfirmation .MuiAlert-standardSuccess,
  .comptitorconfrimation .MuiAlert-filledSuccess,.comptitorconfrimation .MuiAlert-standardSuccess,
  .long-alert .MuiAlert-filledSuccess, .long-alert .MuiAlert-standardSuccess {
    width: 70% !important;
  }
}
@media (min-width: 280px) and (max-width: 480px) {

  .emailconfirmation .MuiAlert-filledSuccess, .emailconfirmation .MuiAlert-standardSuccess,
  .comptitorconfrimation .MuiAlert-filledSuccess, .comptitorconfrimation .MuiAlert-standardSuccess,
  .long-alert .MuiAlert-filledSuccess, .long-alert .MuiAlert-standardSuccess {
    width: 90% !important;
  }

  #content-center {
    .left-card {
      .singel-event-data-list {
        li .span-data .category-tag {
          margin: 10px 0px 8px 0px;
        }

        .event-name {
          .span-data {
            align-items: flex-start;

            .evnt-name-align {
              flex-direction: column;
              align-items: unset;
            }
          }
        }
      }
    }
  }

  /*---- tool-tip---*/
  div.google-visualization-tooltip {
    width: 80% !important;
  }

  .country-flag {
    width: 14px;
  }

  .bg-img {
    .section-title {

      h1,
      h2,
      p {
        font-size: 26px;
      }
    }
  }
}

@media (min-width: 400px) and (max-width: 512px) {
  #why-choose {
    .card-bg {
      img {
        width: 30%;
      }
    }
  }
}

@media (min-width: 280px) and (max-width: 512px) {
  .promo-label{
    display: block;
  }
  .promocode-feild{
    .paymentbtn{
      margin-left: 10px;
    }
  }
  .rangeslider {
    margin-top: 45px !important;
  }

  .startweekends {
    display: block;
  }

  .start-weekon {
    text-align: center;
    margin: 12px 0px 5px;

    label {
      margin-right: 10px;
      margin-top: 5px;
      font-size: 15px;
    }

    button {
      border-radius: 0px;
      font-size: 12px;
      font-weight: 600;
      padding: 4px 10px;
      border: 0px;
    }
  }

  .mob-p-10 {
    padding-top: 10px;
    padding-bottom: 5px;
  }

  .tooltipcustom {
    margin-top: 10px !important;
  }

  .autocomplete-dropdown-container {
    width: 100%;
    margin-top: 0px !important;
  }

  .page-heading {
    h1 {
      display: grid;

      .txt-color-orange {
        margin-top: 10px;
      }
    }
  }

  #why-choose {
    .card-bg {
      width: 100% !important;
    }
  }
}

@media (min-width: 280px) and (max-width: 680px) {

  .event-list-pagination,
  .admin-list-pagination,
  .without-shop-list-pagination {
    display: block;
    justify-content: center;
    width: 100%;
    padding: 0px 10px;
    margin-left: auto;
    margin-right: auto;

    .rows-per-page {
      margin-right: 0px !important;
    }

    .page-left,
    .page-right {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      p {
        text-align: center;
        margin-bottom: 10px;
      }

      .page-1 {
        justify-content: center;
      }

      .page-2 {
        margin-right: 0px;
        margin-bottom: 10px;

        ul {
          justify-content: center;
        }
      }
    }
  }
}

@media (min-width: 280px) and (max-width: 768px) {
  .price-graph {
    .scroll-added-tip-list-col-2,
    .scroll-added-tip-list-col-3 {
      -webkit-column-count: 1 !important;
      /* Chrome/Opera, Safari */
      -moz-column-count: 1 !important;
      /* Mozilla Firefox */
      column-count: 1 !important;
    }

  }

  //event section
  .eventstable #event-details thead tr th {
    width: 100% !important;
  }

  #eve-list-tr td {
    width: 100% !important;
  }

  /*---- tool-tip---*/
  div.google-visualization-tooltip {
    width: 50% !important;

    #testing {
      h5 {
        font-size: 11px !important;
        font-weight: bold !important;
      }

      h6 {
        font-size: 11px !important;
      }
    }
  }

  .container-fluid {
    width: 100%;
  }

  .m-t-m5 {
    margin-top: -83px !important;
  }

  .page-heading {
    h1 {
      margin-bottom: 20px;
    }
  }

  .header-h2 {
    font-size: $font-2;
    margin-top: 50px;
  }

  #content-center {
    width: 100%;

    .left-card {
      .singel-event-data-list {
        .hotel-name {
          display: block;
        }
      }
    }
  }

  /*  event Calendar  */

  .last-btn {
    display: flex !important;
    justify-content: center !important;
    width: fit-content !important;
    margin: 10px auto;
  }

  .features,
  .home-features {
    .homefeaturescontainer {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  /* why choose */
  #why-choose {
    .card-bg {
      width: 50%;
    }

    p {
      margin-bottom: 40px;
    }
  }
}

@media (min-width: 769px) and (max-width: 991.5px) {
  
  .emailconfirmation .MuiAlert-filledSuccess, .emailconfirmation .MuiAlert-standardSuccess,
  .comptitorconfrimation .MuiAlert-filledSuccess, .comptitorconfrimation .MuiAlert-standardSuccess {
    width: 50% !important;
  }

  #why-choose {
    .card-bg {
      width: 50%;
    }

    p {
      margin-bottom: 40px;
    }
  }
}

@media (min-width: 520px) and (max-width: 991.5px) {
  .mini-d-none{
    display: block !important;
  }
  .mob-d-block{
    display: flex !important;
    justify-content: space-between;
  }
  .mob-d-none{
    display: none;
  }
  .mob-btn-last {
    justify-content: flex-end !important;
  }
}
@media (min-width: 280px) and (max-width: 519.5px){
  .refresh-button {
    margin-left: unset !important;
  }
  
}
@media (max-width: 991.5px) {

  .promocode-end{
    position: relative;
  }
  .promocode-feild{
    margin-right: 0px;
  }
  .tool-bar-ht {
    min-height: 500px !important;
    max-height: 920px !important;

    .apexcharts-canvas {
      overflow: inherit !important;
      height: 600 !important;
    }

    .apexcharts-toolbar {
      top: -41px !important;
      margin: 10px 0px !important;
    }

    .price-graph {
      .apexcharts-legend {
        margin-top: 30px;
      }
    }
  }

  .price-chart-ht {
    min-height: 320px !important;
    max-height: 500px !important;

    .apexcharts-canvas,
    svg {
      overflow: inherit !important;
      height: 500px !important;
    }

    .price-graph {
      .apexcharts-legend {
        margin-top: 30px;
      }
    }
  }

  .justify-spacebwn {
    justify-content: center !important;
    display: block !important;
  }

  // .selected-packages {
  //   margin-top: 15px !important;
  // }

  .text-left-center {
    text-align: center !important;
  }

  .img-responsive {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .mob-order {
    flex-direction: column-reverse;
    display: flex;
    align-items: unset;
    padding: 0px 15px;
  }

  .h-590,
  .h-690 {
    min-height: fit-content !important;
  }

  .mb-p-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .zigzag-bg,
  .zigzag-white {
    height: fit-content;
  }

  .p-l-0 {
    padding-right: 0px !important;
  }

  .p-r-0 {
    padding-left: 0px !important;
  }

  .bg-img {
    .section-title {
      padding: 0px 15px;

      p {
        font-size: 25px;
      }
    }
  }

  .css-1lvtzne {
    max-width: fit-content;
  }

  #content-center {
    .left-card {
      height: fit-content;

      .card-6 {
        width: 100% !important;
      }
    }
  }

  .wd-100 {
    width: 100% !important;
  }

  .features,
  .home-features {
    .wd-100 {
      padding-bottom: 50px;

      &:last-child {
        padding-bottom: 0px;
      }
    }
  }

  .faq {
    .faqcontainer {
      .row {
        align-items: unset;
        display: block;

        .left-img img {
          border-radius: 15px 15px 0px 0px;
        }
      }
    }
  }

  .background-blue {
    margin-bottom: 15px;
    .blue-bannercontainer {
      width: 100% !important;
      margin-bottom: 15px!important;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
}
#root .header-manager .sticky-header .logo-text, #root .header-manager .sticky-header .logo-text1 {
  height: 0px !important;
  cursor: pointer;
}
.background-white-cs {
  background-color: #fff !important;
  position: sticky;
  top: 63px;
  z-index: 99;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15) !important;
  margin-bottom: 15px;
}
.mobile-view-manager {
  p.logo-text {
    img {
      display: none;
    }
  }
}
@media (min-width: 992px) and (max-width:1199px) {
  .leftside-select, .centerside-select {
    .leftside-checkbox {
      margin-bottom: 20px !important;
    }
  }
  .source-channels{
    max-height: 345px !important;
    min-height: 345px !important;
  }
}
@media (min-width: 992px) and (max-width:1275px) {
  .faq {
    .faqcontainer {
      .service-desc {
        width: 100%;
      }
    }
  }
}

@media (min-width: 768px) {
  .h-300 {
    min-height: 270px;
    max-height: 270px !important;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--first-color);
    }
  }
}

@media (min-width: 1900px) and (max-width: 2000px) {
  .pgae-height-fixed {
    min-height: 62vh;
  }
}

@media screen and (max-width: 800px) {
  div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner p {
    margin: 0 0px 4px !important;
  }

  div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner p {
    font-size: 10px !important;
    margin-bottom: 0px !important;
    line-height: 13px !important;
  }

  #hs-eu-close-button-container a#hs-eu-close-button[role=button] {
    line-height: 25px !important;
    font-size: 24px !important;
  }

  div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner {
    padding: 0px 14px 0px !important;
  }
}

.butndisabled {
  opacity: 0.7 !important;
  pointer-events: none !important;
}
/*-- Hubspot chat icon hidden --*/
.hubspothidden #hubspot-messages-iframe-container {
  display: none !important;
}
.hs-cookie-notification-position-bottom{
  padding: 15px !important;
}
.MuiTooltip-popperArrow {
  .MuiTooltip-tooltip {
    font-size: 12px;
    background-color: #222;
    .MuiTooltip-arrow {
      color: #222;
    }
  }
}
.x2-icon-svg {
  width: 1.2em!important;
  height: 1.2em!important;
  color: #000!important;
}
.icons-svg-group {
  display: flex;

  button {
    display: flex;
    margin-right: 5px;
    width: 30px;
    height: 30px;
    align-items: center;
    padding: 0px;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #000;
    svg {
      width: 1.2em;
      height: 1.2em;
    }
  }
}
.custom-tooltip {
  p {
    text-align: left;
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 500;
    // span {
    //   width: 80px!important;
    //   display: inline-block!important;
    // }
  }
}

// left side menu manager child users class START
.positionavoid {
  top: 0px;
}
.icon-fullscreen {
  height: fit-content!important;
  color: #07748f!important;
  background-color: #fff!important;
  padding: 3px 3px!important;
  // position: relative!important;
  // top: 6px !important;
  // right: -160px !important;
  border-radius: 3px!important;
  cursor: pointer!important;
  svg {
    width: 18px;
    height: 18px;
    cursor: pointer!important;
  }
}
.overflow-auto {
  overflow: auto;
}
.main-width-reduce {
  margin-left: 220px;
}
.header-manager{
  margin-bottom: 3px;
  height: 55px;
  .logo-text1 {
    display: none;
    img {
      display: none;
    }
  }
  .logo-text {
    display: none;
    img {
      display: none;
    }
  }
  .position-header {
    box-shadow: none!important;
    position: initial!important;
    display: block!important;
    padding: 0px!important;
    width: 100% !important;
    border: 0px!important;
    background: transparent!important;
    background-color: transparent!important;
    background: transparent !important;
  }
  .header-1, .menu-bar-ht, p.logo-text, .menu-btn-logo, .menubox {
    display: block;
    width: 100%;
  }
  
  p.logo-text {
    width: 100%!important;
    text-align: center!important;
    height:0px!important;
  }
  .menubox {
    margin-bottom: 10px;
    text-align: center;
  }
}

#root .header-manager .stagging-header .topbar-style {
  background-color: transparent !important;
  box-shadow: none !important;
  border: 0px !important;
  width: 100% !important;
  position: initial !important;
  padding: 0px !important;
  background: transparent!important;
}
#root .header-manager .stagging-header .topbar-style .container .menubox .dropbtn , 
#root .header-manager .stagging-header .topbar-style .container .menubox a {
  margin: 10px 0px 0px 0px!important;
}
#root .header-manager .stagging-header .topbar-style .container .menubox .dropdown-content {
  min-width: 161px;
}
.user-account-details {
  margin-bottom: 13px;
  h3 {
    font-size: 14px;
    margin: 5px 0px 0px;
  }
  .font-competitor {
    font-size: 13px;
    text-transform: capitalize!important;
  }
}
.display-flexer-manager {
  display: flex;
  justify-content: space-between;
  .download-btn {
    margin: 0px;
  }
}
.reducefullscreenwidth {
  margin-left: 220px;
}
.skiptour-manager {
  .skip-tour {
    left: 20px!important;
  }
}
@media (min-width: 1300px) and (max-width: 1397px) {
  .reducefullscreenwidth {
  .container {
      width: 1128px!important;
  }
}
#root .header-manager .header-1, 
#root .header-manager .menu-bar-ht, 
#root .header-manager p.logo-text, 
#root .header-manager .menu-btn-logo,
 #root .header-manager .menubox {
  display: block;
  width: 100% !important;
}
}

@media (min-width: 1250px) and (max-width: 1299px) {
    .reducefullscreenwidth .container {
        width: 1043px !important;
    }
#root .header-manager .header-1, 
#root .header-manager .menu-bar-ht, 
#root .header-manager p.logo-text, 
#root .header-manager .menu-btn-logo,
 #root .header-manager .menubox {
  display: block;
  width: 100% !important;
}
}
@media (min-width: 1200px) and (max-width: 1249px) {
    .reducefullscreenwidth .container {
        width: 964px !important;
    }
#root .header-manager .header-1, 
#root .header-manager .menu-bar-ht, 
#root .header-manager p.logo-text, 
#root .header-manager .menu-btn-logo,
 #root .header-manager .menubox {
  display: block;
  width: 100% !important;
}
}

@media (min-width: 1200px) and (max-width: 1249px) {
  @media (min-width: 1200px) and (max-width: 1397px) {
    .reducefullscreenwidth .container {
        width: 964px !important;
    }
}
#root .header-manager .header-1, 
#root .header-manager .menu-bar-ht, 
#root .header-manager p.logo-text, 
#root .header-manager .menu-btn-logo,
 #root .header-manager .menubox {
  display: block;
  width: 100% !important;
}
}
@media (min-width: 280px) and (max-width: 480px) {
  #root .page-het .reducefullscreenwidth {
  margin-left: 213px!important;
  }
}
// left side menu manager child users class END
.black-text, #blackText {
  color: #000 !important;
  svg{
    fill: #000 !important
  }
  span{
    color: #000 !important
  }
}
.black-icon, #blackIcon {
  fill: #000 !important
}
.bg-secondary {
  background-color: var(--second-color) !important;
}
.black-text-step {
  text{
    color: #000 !important;
    fill: #000 !important
  }
}
.iframe-hubspothide .hs-cookie-notification-position-bottom {
  display: none!important;
  }
  body.hubspothidden .hs-cookie-notification-position-bottom {
  display: none!important;
  }
  body.hubspothidden #hs-eu-cookie-confirmation {
  display: none!important;
  }
.create-new-user{
  align-items: center;
  input,  select {
    height: 35px !important;
    filter: grayscale(100%);
    background-repeat: no-repeat;
    background-position: 0;
    padding-left: 30px;
    background-size: 26px;
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
   
  }
}
  .new-user-add {
    display: flex;
    justify-content: space-between;
    width: 100%;
    
}
.new-user-add-btn {
   border: 2px solid var(--first-color);
    background: var(--first-color) !important;
    padding: 6px 13px;
    font-size: 1.4rem;
    font-weight: 500;
    border-radius: 5px;
    display: flex;
    color: #fff !important;
    margin-left: auto;
    width: -moz-fit-content;
    margin-bottom: 5px !important;
    margin-left: 15px;
    width: fit-content;
    cursor: pointer;
    margin-bottom: 15px;
    align-items: center;
    outline: none !important;
    svg{
      margin-bottom: -2px !important;
      margin-right: 5px !important;
    }
}

button#adduserBtn{
  margin-left: auto;
  margin-bottom: 15px !important;
}
.view-pass {
  display: block;
  align-items: center;
  position: relative;
  svg{
    width: 20px;
    height: 20px;
    fill: #a0a0a0;
    margin-left: 3px;
  }
  input[name="empasswordailId"] {
    padding-right: 30px;
  }
}
.view-hide-icon {
  margin: -3px 5px 2px 5px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  right: 0px;
  top: 12px;
}
.newuser-holder {
  justify-content: flex-start !important;
  .create-new-user{
    align-items: flex-start !important;
    .error-msg {
      min-height: 18px;
      display: block !important;
    }
    .tx-label{
      margin-top: 8px !important;
    }
  }
}
.w-30{
  width: 30% !important;
}
.w-70{
  width: 60% !important;
}