.sweet-loading {
  position: relative;
  width: 100%;
  height: 100vh;
  right: 0%;
  top: 10%;
  bottom: 0;
  left: 0%;
  background: transparent;
  // z-index: 999;
  margin-top: auto;

  .img-wd {
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    img {
      width: 100%;
      height: auto;
    }
  }

  span {
    margin-left: auto !important;
    margin-right: auto !important;
    display: flex !important;
    position: relative;
    top: 30%;
  }

  .loader {
    width: 8px;
    height: 40px;
    border-radius: 4px;
    display: block;
    margin: 20px auto;
    position: relative;
    top: 45%;
    background: currentColor;
    color: #FF914E;
    box-sizing: border-box;
    animation: animloader 0.3s 0.3s linear infinite alternate;
  }

  .loader::after,
  .loader::before {
    content: '';
    width: 8px;
    height: 40px;
    border-radius: 4px;
    background: currentColor;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    box-sizing: border-box;
    animation: animloader 0.9s 0.85s linear infinite alternate;
  }

  .loader::before {
    left: -20px;
    animation-delay: 0s;
  }

  @keyframes animloader {
    0% {
      height: 48px
    }

    100% {
      height: 4px
    }
  }
}

/* media query */
@media (min-width: 481px) and (max-width: 768px) {
  .sweet-loading {
    .img-wd {
      width: 20%;
      left: 41%;
    }
  }
}

@media (min-width: 399px) and (max-width: 480px) {
  .sweet-loading {
    .img-wd {
      width: 25%;
      left: 39%;
    }
  }
}

@media (min-width: 280px) and (max-width: 398px) {
  .sweet-loading {
    .img-wd {
      width: 30%;
      left: 35%;
    }
  }
}


.lds-dual-ring {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid#FF914E;
  border-color: #FF914E transparent #FF914E transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fixed-loader{
  position: fixed;
  top: 0px !important;
  bottom: 0px !important;
  z-index: 9999999;
  background: #00000057;
}
