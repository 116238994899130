/*--style import constant--*/
@import "../../style.scss";

/*--Logo style--*/
#root {

    /*-- empty ht --*/
    .empty-ht {
        margin-top: 64px;
        position: relative !important;
    }

    .empty-ht-1 {
        margin-top: 0px;
        position: relative !important;
    }

    .sticky-header {
        z-index: 999;
        position: fixed !important;
        min-height: 64px;
        padding: 0px 10px;

        .logo-text,
        .logo-text1 {
            height: 64px !important;
            cursor: pointer;

            img {
                transition: all .4s;
                transform: scale(0.8);
                -moz-transform: scale(0.8);
                -webkit-transform: scale(0.8);
                -o-transform: scale(0.8);
                -ms-transform: scale(0.8);
                cursor: pointer;
                image-rendering: -webkit-optimize-contrast;
            }
        }
    }

    .position-header {
        position: relative;
        z-index: 999;
        padding: 10px 10px;
        min-height: 84px;
    }

    //staging header style
    .stagging-header {
        .topbar-style {
            background-color: var(--second-color) !important;
            border-color: rgba(231, 231, 231, 0) !important;
            box-shadow: 0 0 10px rgb(0 0 0 / 15%) !important;

            .container {
                padding: 0px;

                .logo-text {
                    width: 16%;
                    height: 64px;
                    margin-right: 0px !important;
                    border-radius: 3px;

                    .logo-1 {
                        width: auto;
                        height: 100%;
                        transition: all .4s;
                        cursor: pointer;
                    }

                    .logo {
                        width: 30%;
                    }
                }

                .menubox {
                    justify-content: center;
                    // width: 300px;

                    button,
                    a {
                        text-transform: uppercase;
                        white-space: normal;
                        text-decoration: none;
                        color: #ffffff;
                        font-size: 15px;
                        font-weight: 500;
                        padding: 0px 2px 4px;
                        border-radius: 0;
                        margin: 9px 12px 0;
                        background: transparent;
                        outline: 0px !important;
                        border: 0px;
                        box-shadow: none !important;
                        cursor: pointer;

                        a {
                            color: #343833;
                            text-decoration: none;
                        }

                        &:first-child {
                            margin-right: 10px;
                        }
                    }

                    .un {
                        display: inline-block;
                    }

                    .un:after {
                        position: relative;
                        top: 4px;
                        content: '';
                        width: 0px;
                        height: 3px;
                        display: block;
                        background: var(--first-color);
                        transition: 300ms;
                    }

                    .un:hover:after {
                        width: 100%;
                    }
                }

                .MuiAvatar-root {
                    width: 30px;
                    height: 30px;
                    background-color: #5C6BC0;
                    margin: 6px 0px 0px 0px;
                }
            }

            .menusvg {
                padding-left: 5px;
                padding-right: 5px;

                svg {
                    font-size: 21px;
                    color: #fff !important;
                }
            }
        }

        .languages .dropdown button.dropbtn {
            color: #fff;

            &:hover,
            &:focus {
                background-color: transparent;
            }
        }

        .qsLoginBtn {
            color: #fff;
            padding: 8px 25px;
            border: 0px;
            border-radius: 30px;
            margin-right: 18px;
            background: linear-gradient(to left, var(--first-color) 50%, #ffffff 50%) right;
            background-size: 200%;
            transition: .4s ease-out;

            #desc-login {
                svg {
                    margin-right: 8px;
                    font-size: 16px;
                    position: relative;
                    top: 3px;
                }
            }

            &:hover {
                background-position: left;
                color: var(--second-color);
            }
        }
    }

    //Live header style
    .live-header {
        .topbar-style {
            background-color: #ffffff !important;
            border-color: rgba(231, 231, 231, 0) !important;
            box-shadow: 0 0 10px rgb(0 0 0 / 15%) !important;

            .container {
                padding: 0px;

                .logo-text {
                    width: 20%;
                    height: 58px;
                    margin-right: 0px !important;
                    border-radius: 3px;

                    .logo-1 {
                        width: auto;
                        height: 100%;
                        transition: all .4s;
                        cursor: pointer;
                    }

                    .logo {
                        width: 30%;
                    }
                }

                .menubox {
                    justify-content: center;
                    // width: 300px;

                    button,
                    a {
                        text-transform: uppercase;
                        white-space: normal;
                        color: #343833;
                        font-size: 15px;
                        font-weight: 500;
                        padding: 0px 2px 4px;
                        border-radius: 0;
                        margin: 9px 12px 0;
                        background: transparent;
                        outline: 0px !important;
                        border: 0px;
                        box-shadow: none !important;
                        cursor: pointer;

                        a {
                            color: #343833;
                            text-decoration: none;
                        }

                        &:first-child {
                            margin-right: 10px;
                        }
                    }

                    .un {
                        display: inline-block;
                    }

                    .un:after {
                        position: relative;
                        top: 4px;
                        content: '';
                        width: 0px;
                        height: 3px;
                        display: block;
                        background: var(--first-color);
                        transition: 300ms;
                    }

                    .un:hover:after {
                        width: 100%;
                    }
                }

                .MuiAvatar-root {
                    width: 30px;
                    height: 30px;
                    background-color: #5C6BC0;
                    margin: 6px 0px 0px 0px;
                }
            }

            .menusvg {
                padding-left: 5px;
                padding-right: 5px;

                svg {
                    font-size: 21px;
                    color: #818180 !important;
                }
            }
        }

        .qsLoginBtn {
            color: #fff;
            padding: 8px 25px;
            border: 0px;
            border-radius: 30px;
            margin-right: 18px;
            background: linear-gradient(to left, var(--first-color) 50%, var(--second-color) 50%) right;
            background-size: 200%;
            transition: .4s ease-out;

            #desc-login {
                svg {
                    margin-right: 8px;
                    position: relative;
                    top: 3px ;
                }
            }

            &:hover {
                background-position: left;
            }
        }
    }

    #mob-login {
        display: none;
    }

    /*--Active--*/
    .activePage:after {
        position: relative;
        top: 4px;
        content: '';
        width: 100%;
        height: 3px;
        display: block;
        background: var(--first-color);
    }
}

.header-dropdown {
    .MuiPaper-root {
        ul {
            padding: 5px 0px;

            li {
                padding-top: 0px !important;
                padding-bottom: 0px !important;
                min-height: 30px !important;

                p {
                    font-size: 13px;
                    font-weight: 600;
                    color: #818180;

                    svg {
                        margin-right: 10px;
                        position: relative;
                        top: 3px;
                        color: #343833 !important;
                    }
                }
            }

            .user-list-icon {
                svg {
                    font-size: 18px;
                    margin-right: 8px;
                }
            }
        }
    }
}

.menu-bar-ht {
    justify-content: space-between;
}

/*--Dropdown button--*/
.dropbtn {
    svg {
        margin-left: 7px;
    }

    i {
        font-style: normal !important;
    }
}

.drobdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    background: #ffffff;
    min-width: 150px;
    margin-left: 6%;
    position: absolute;
    z-index: 1;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 20%);
    padding: 5px 0px 10px;
    border-radius: 0px 0px 5px 5px;
}

.extented-width {
    min-width: 162px !important;
    margin-left: 7.5% !important;
}

.dropdown-content button {
    margin-left: 13px !important;
    font-size: 13px !important;
    display: block;
    color: #333 !important;

    &:hover {
        color: var(--first-color) !important;
    }
}

.dropdown-content button.activePage1 {
    color: var(--first-color) !important;
}

.dropdown-content button:hover {
    background: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

/*--- Active Color---*/
#menu-appbar {
    .MuiPaper-root {
        li.activePage {
            p {
                color: var(--first-color) !important;
            }
        }
    }
}

/*---session time out ----*/
.time-out-close-btn {
    width: 35px;
    height: 35px;
    background-color: #e9e9e9;
    border-radius: 50% !important;
    position: relative;
    transform: rotate(45deg);
    margin-left: auto !important;
    margin-right: -13px !important;
    cursor: pointer;
    margin-top: -13px !important;
    font-size: 25px !important;
    text-align: center;
    margin: 0px;
    padding: 0px !important;
}

.time-out-popup {
    font-family: $montserrat;
    font-display: swap;
    .MuiDialog-paperWidthSm {
        min-width: 500px;
        overflow: unset;
    }

    h2 {
        font-size: 20px;
    img {
        width: 150px;
        height: auto;
      }
    }

    .MuiDialogContent-root {
        overflow: hidden;
    }
}

.time-out-title-svg {
    padding-bottom: 0px !important;
    text-align: center;

    h2 img {
        width: 23%;
        height: auto;
    }
}

.time-out-title {
    text-align: center;
    padding: 6px 24px !important;

    h2 {
        font-size: 20px;
    }
}

.time-out-desc {
    font-size: 16px !important;
    text-align: center;
    padding: 0px 24px !important;
    margin-bottom: 0px !important;
    span {
        font-family: $montserrat;
        font-display: swap;
    }
}

.continue-ok-btn {
    padding: 12px 10px 24px !important;
    justify-content: center !important;

    .login-2 {
        background-color: var(--first-color);
        border: none;
        color: #fff;
        padding: 8px 35px;
        border-radius: 50px;

        &:hover {
            background-color: var(--second-color);
        }
    }
}

.mobilemenu {
    margin: 0;
    font-family: $montserrat;
    font-display: swap;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    text-align: center;
    color: #343833;
}

.myaccountname {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
}

/*-- language-login --*/
.languages {
    margin-right: 15px;
    .dropdown {
        button.dropbtn {
            position: relative;
            top: 5px;
            background-color: transparent;
            color: #343833;
            border: 1px solid #e2e2e2;
            padding-right: 5px;
            padding-left: 7px;
            font-size: 14px;
            width: 115px;
            font-weight: 500;
            text-align: left;
            justify-content: space-between;
            display: flex;

            img {
                margin-right: 6px;
            }

            .arrowimg {
                position: relative;
                top: 3px;
                text-align: right;
            }

            &:focus,
            &:active,
            &:hover {
                background-color: #f5f8fa;
                outline: 0px !important;
            }
        }
    }

    .dropdown-content {
        padding: 9px 8px 0px;
        min-width: 115px;
        margin-left: 0px;
        width: 115px;

        button {
            display: block;
            margin-bottom: 10px;
            font-size: 14px;
            color: #343833;
            border: 0px;
            padding: 0px 10px 0px 0px;
            margin-left: 2px !important;
            background-color: transparent;

            &:focus,
            &:active,
            &:hover {
                outline: 0 !important;
                box-shadow: none !important;
            }
        }
    }
}

.menu-btn-logo {
    display: flex;
    align-items: center;
}

.MuiPaper-elevation {
    ul {
        li {
            p {
                font-family: 'Montserrat', sans-serif !important;
                font-display: swap;
            }
        }
    }
}

/*--Media Query--*/
@media (min-width: 935.5px) and (max-width: 1199px) {
    html[lang="de"] {
        #root {
            .stagging-header {
                .topbar-style {
                    .container {
                        .menubox {
                            button,
                            a {
                                margin: 9px 7px 0px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 899.5px) and (max-width:1199px) {
    #root {
        .live-header {
            .topbar-style .container .logo-text {
                max-width: 80% !important;
                width: 15% !important;
                margin-right: 0px;

                img {
                    width: 100% !important;
                    height: 70% !important;
                    margin-top: 7px;
                }
            }

            .sticky-header {
                .logo-text {
                    img {
                        -webkit-transform: scale(0.9) !important;
                    }
                }
            }
        }
        #root .stagging-header .topbar-style .container .mobile-view-manager p.logo-text {
            width: 100%!important;
            text-align: center;
            img {
                width: 100px !important;
            }
        }

        .stagging-header {
            .topbar-style .container .logo-text {
                max-width: 80% !important;
                width: 15% !important;
                margin-right: 0px;

                img {
                    width: 100% !important;
                    height: 75% !important;
                    margin-top: 7px;
                }
            }

            .sticky-header {
                .logo-text {
                    img {
                        -webkit-transform: scale(0.9) !important;
                    }
                }
            }

        }

        #root {
            .header {
                width: 100% !important;
            }

            .header-1 {
                width: 100% !important;
                padding: 0px 20px;
            }
        }

        .stagging-header {
            .topbar-style {
                .container {
                    .menubox {

                        button,
                        a {
                            margin: 9px 7px 0px !important;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 900px) and (max-width: 992px) {
    .live-header {
        .topbar-style .container .logo-text {
            max-width: 80% !important;
            width: 15% !important;
            margin-right: 0px;

            img {
                width: 100% !important;
                height: 70% !important;
                margin-top: 7px;
            }
        }

        .sticky-header {
            .logo-text {
                img {
                    -webkit-transform: scale(0.9) !important;
                }
            }
        }
    }

    #root .stagging-header .topbar-style .container .mobile-view-manager p.logo-text {
        width: 100%!important;
        text-align: center;
        img {
            width: 100px !important;
        }
    }

    .stagging-header {
        .topbar-style .container .logo-text {
            max-width: 80% !important;
            width: 15% !important;
            margin-right: 0px;

            img {
                width: 100% !important;
                height: 75% !important;
                margin-top: 7px;
            }
        }

        .sticky-header {
            .logo-text {
                img {
                    -webkit-transform: scale(0.9) !important;
                }
            }
        }
    }

    #root {
        .header {
            width: 100% !important;
        }

        .header-1 {
            width: 100% !important;
            padding: 0px 20px;
        }
    }
}

@media (max-width: 600px) {
    .background-blue {
        top: 58px;
    }

    .time-out-popup {
        .MuiDialog-paperWidthSm {
            min-width: 70%;
            max-width: 70%;
        }
    }

    .time-out-title-svg {
        h2 img {
            width: 30%;
        }
    }

    .time-out-title {
        h2 {
            font-size: 17px;
        }
    }

    .time-out-desc {
        font-size: 13px !important;
        padding: 0px 0px !important;
        font-family: $montserrat;
        font-display: swap;

        span {
            font-family: $montserrat;
            font-display: swap;
        }
    }

    .continue-ok-btn {
        padding: 12px 10px 24px !important;
        justify-content: center !important;

        .login-2 {
            background-color: var(--first-color);
            border: none;
            color: #fff;
            padding: 6px 30px;
            border-radius: 50px;
            font-size: 12px;

            &:hover {
                background-color: var(--second-color);
            }
        }
    }

    .header-dropdown {
        .MuiPaper-root {
            ul {
                padding: 5px 0px;

                li {
                    min-height: 35px !important;
                }
            }
        }
    }

    #menu-appbar {
        .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
            .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
                min-height: auto;
            }
        }
    }
}

@media (min-width: 280px) and (max-width: 480px) {
    .time-out-title-svg {
        h2 img {
            width: 50%;
        }
    }

    .header-dropdown {
        .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
            width: 60% !important;
        }
    }
}

@media (min-width: 280px) and (max-width: 313.5px) {
    .live-header {
        .topbar-style .container .logo-text1 {
            .logo {
                width: 100% !important;
                height: 65% !important;
            }
        }
    }

    .stagging-header {
        .topbar-style .container .logo-text1 {
            .logo {
                width: 100% !important;
                height: 75% !important;
            }
        }

    }

    #root .sticky-header .logo-text1 img {
        -webkit-transform: scale(0.8);
    }
}

@media (min-width: 314px) and (max-width: 425px) {
    .live-header {
        .topbar-style .container .logo-text1 {
            .logo {
                width: 100% !important;
                height: 75% !important;
            }
        }
    }

    .stagging-header {
        .topbar-style .container .logo-text1 {
            .logo {
                width: 100% !important;
                height: 80% !important;
            }
        }
    }
}

@media (min-width: 280px) and (max-width:935.5px) {
    html[lang="de"] {
        .languages {
            .dropdown button.dropbtn {
                font-size: 12px;
                width: 95px;
                margin-right: 10px;
                margin-left: 10px;

                .lang-details {
                    display: flex;
                    align-items: center;
                }

                span {
                    white-space: nowrap;
                    overflow: hidden;
                    display: inline-block;
                    max-width: 3ch;
                }
            }

            .dropdown-content {
                min-width: 95px;
                width: 82%;
                margin-left: 10px;
                padding: 9px 7px 0px;

                button {
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                    overflow: hidden;
                    display: inline-block;
                    max-width: 7.5ch;
                    font-size: 12 !important;
                    img {
                        margin-right: 5px;
                    }
                }
            }
        }

        #root {
            .header-1 {
                width: 100% !important;
                padding: 0px 20px;
            }

            .position-header {
                min-height: fit-content;
            }

            .topbar-style .container {
                .languages .MuiSelect-select {
                    font-size: 1.2rem;
                    width: fit-content;
                }

                .MuiAvatar-root {
                    width: 28px;
                    height: 28px;
                    margin: 6px 0px 0px 0px;
                }
            }

            .stagging-header {
                .qsLoginBtn {
                    border-radius: 50%;
                    padding: 5px 8px;
                    margin-left: 5px;
                    margin-right: 0px;
                }

                .topbar-style .container .logo-text1 {
                    margin-right: 0px !important;
                    justify-content: flex-start;
                    max-width: 145px;

                    .logo {
                        width: 100%;
                        height: 80%;
                    }
                }

                .sticky-header {
                    padding: 0px 10px;
                    min-height: 58px;

                    .logo-text1,
                    .logo-text {
                        height: 58px !important;
                        width: 120px !important;

                        img {
                            margin: auto !important;
                            -webkit-transform: scale(0.9) !important;
                        }
                    }
                }
            }

            .live-header {
                .qsLoginBtn {
                    border-radius: 50%;
                    padding: 5px 8px;
                    margin-left: 5px;
                    margin-right: 0px;
                }

                .topbar-style .container .logo-text1 {
                    margin-right: 0px !important;
                    justify-content: flex-start;
                    max-width: 145px;

                    .logo {
                        width: 100%;
                        height: 75%;
                    }
                }

                .sticky-header {
                    padding: 0px 10px;
                    min-height: 58px;

                    .logo-text1,
                    .logo-text {
                        height: 58px !important;

                        img {
                            margin: auto !important;
                            -webkit-transform: scale(0.9) !important;
                        }
                    }
                }
            }

            #desc-login {
                display: none;
            }

            #mob-login {
                display: block;
            }

            .css-1h9hlp5-MuiTypography-root {
                -webkit-flex-grow: unset !important;
            }
        }

        #root .topbar-style .container {
            margin: 0px !important;
        }

        #menu-appbar {
            ul {
                width: 150px !important;
            }
        }
    }
}

@media (min-width: 280px) and (max-width: 899.5px) {
    .languages {
        .dropdown button.dropbtn {
            font-size: 12px;
            width: 95px;
            margin-right: 10px;
            margin-left: 10px;

            .lang-details {
                display: flex;
                align-items: center;
            }

            span {
                white-space: nowrap;
                overflow: hidden;
                display: inline-block;
                max-width: 3ch;
            }
        }

        .dropdown-content {
            min-width: 95px;
            width: 82%;
            margin-left: 10px;
            padding: 9px 7px 0px;

            button {
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: hidden;
                display: inline-block;
                max-width: 7.5ch;
                font-size: 12 !important;

                // span{
                //     white-space: nowrap;
                //     overflow: hidden;
                //     display: inline-block;
                //     max-width: 3ch;
                // }
                img {
                    margin-right: 5px;
                }
            }
        }
    }

    #root {
        .header-1 {
            width: 100% !important;
            padding: 0px 20px;
        }

        .position-header {
            min-height: fit-content;
        }

        .topbar-style .container {
            .languages .MuiSelect-select {
                font-size: 1.2rem;
                width: fit-content;
            }

            .MuiAvatar-root {
                width: 28px;
                height: 28px;
                margin: 6px 0px 0px 0px;
            }
        }

        .stagging-header {
            .qsLoginBtn {
                border-radius: 50%;
                padding: 5px 8px;
                margin-left: 5px;
                margin-right: 0px;
            }

            .topbar-style .container .logo-text1 {
                margin-right: 0px !important;
                justify-content: flex-start;
                max-width: 145px;
                height: 64px ;
                align-items: center;

                .logo {
                    width: 100%;
                    height: 100%;
                }
            }

            .sticky-header {
                padding: 0px 10px;
                min-height: 58px;

                .logo-text1,
                .logo-text {
                    height: 58px !important;
                    width: 120px !important;

                    img {
                        margin: auto !important;
                        -webkit-transform: scale(0.9) !important;
                    }
                }
            }
        }

        .live-header {
            .qsLoginBtn {
                border-radius: 50%;
                padding: 5px 8px;
                margin-left: 5px;
                margin-right: 0px;
            }

            .topbar-style .container .logo-text1 {
                margin-right: 0px !important;
                justify-content: flex-start;
                max-width: 145px;

                .logo {
                    width: 100%;
                    height: 75%;
                }
            }

            .sticky-header {
                padding: 0px 10px;
                min-height: 58px;

                .logo-text1,
                .logo-text {
                    height: 58px !important;

                    img {
                        margin: auto !important;
                        -webkit-transform: scale(0.9) !important;
                    }
                }
            }
        }

        #desc-login {
            display: none;
        }

        #mob-login {
            display: block;
        }

        .css-1h9hlp5-MuiTypography-root {
            -webkit-flex-grow: unset !important;
        }
    }

    #root .topbar-style .container {
        margin: 0px !important;
    }

    #menu-appbar {
        ul {
            width: 150px !important;
        }
    }
}

@media (min-width: 280px) and (max-width:399.5px) {
    .languages {
        margin-right: 2px;

        .dropdown-content {
            min-width: 78px;
            width: 80%;
            margin-left: 10px;
            padding: 9px 3px 0px;

            button {
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: hidden;
                display: inline-block;
                max-width: 8ch;
                font-size: 12 !important;

                // span{
                //     white-space: nowrap;
                //     overflow: hidden;
                //     display: inline-block;
                //     max-width: 3ch;
                //     font-size: 12 !important;
                // }
                img {
                    width: 18px;
                    height: auto;
                    margin-right: 5px;
                }
            }
        }
    }

    .languages .dropdown button.dropbtn {
        font-size: 12px;
        width: 78px;
        margin-right: 4px;
        padding-left: 5px;

        .lang-details {
            display: flex;
            align-items: center;
        }

        .arrowimg {
            top: 0px !important;

            svg {
                margin-left: 3px;
            }
        }

        img {
            width: 18px;
            height: auto;
            margin-right: 5px;
        }

        span {
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
            max-width: 3ch;
        }
    }
}
