/*--style import constant--*/
@import "../../style.scss";

/*--Footer Section--*/
html[lang="de"] {
    .footer-content {
        .container {
            .footer-section {
                display: block;
                justify-content: center;

                ul,
                .footer-menu {
                    justify-content: center;
                    padding: 0px !important;
                }
            }
        }
    }
}

.footer-bottom {
    margin-top: auto;
}

.footer-content {
    background: var(--second-color);
    color: rgba(255, 255, 255, 0.75);
    padding: 15px 0px;

    .container {
        .footer-section {
            justify-content: space-between;
            display: flex;

            p {
                margin: 0px;
            }

            ul {
                list-style: none;
                display: flex;
                margin: 10px 15px;

                li {
                    margin-right: 7px;

                    button,
                    a {
                        padding: 0px;
                        white-space: normal;
                        background-color: transparent;
                        border: 0px !important;
                        font-size: 15px;
                        box-shadow: none;
                        outline: 0px;
                        color: #fff;
                        font-weight: 500;

                        &:hover,
                        &:active,
                        &:focus {
                            color: #343833;
                            box-shadow: none;
                            outline: 0px;
                        }
                    }

                    &:last-child {
                        margin-right: 0px;
                    }
                }
            }
        }
    }
}

.footer-menu {
    display: flex;
    margin: 10px 15px;
    align-items: center;

    a,
    button {
        color: #fff;
        font-size: 15px;
        font-weight: 500;
        margin-right: 25px;
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            color: #343833;
        }
    }

    button {
        padding: 0;
        background-color: transparent;
        border: 0px !important;
        margin-right: 25px;
        text-transform: capitalize;

        &:focus,
        &:active,
        &:hover {
            background-color: transparent;
            outline: 0 !important;
            border: 0px !important;
            color: #343833;
        }
    }
}

/*--Contact Us--*/
.contact-form {
    margin: 0px auto 30px;
    width: 100%;

    h6 {
        font-size: 27px;
        text-align: center;
        font-weight: 700;
        color: #ffffff;
        text-transform: uppercase;
        margin: 30px 0px 30px;
    }

    .form-control {
        height: 50px;
        margin-bottom: 26px;
        border-radius: 7px;

        &::placeholder {
            text-align: center;
            color: #343833;
        }
    }

    textarea.form-control {
        height: 135px;
    }

    .submit-form-btn {
        text-align: center;

        button {
            height: 43px;
            padding: 7px 38px;
            color: #fff;
            font-weight: 600;
            letter-spacing: 1px;
            // background-color: #e3e3e3;
            background-color: var(--first-color);
            transition: all 0.5s linear;

            &:hover {
                background-color: #72b0c0;
            }
        }
    }

    .btn-wd-100 {
        button {
            width: 100%;
        }
    }
}

.contact-form-rates {
    width: 77% !important;
}

/*--Copy rights--*/
.copy-rights {
    text-align: center;

    p {
        margin: 10px 0px 10px;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.6;
        color: #fff;
    }
}

/*--Privacy Policy--*/
.footer-video-form {
    .row {
        display: block;
        align-items: center;
    }
}

.s-links-footer {
    text-align: center;
    margin-top: 12px;
    margin-bottom: 15px;

    span {
        a {
            margin: 0px 10px;
        }

        svg {
            color: #fff;
            font-size: 18px;
        }
    }
}

/*--Media Query--*/
@media (min-width: 992px) and (max-width: 1199px) {
    .contact-form textarea.form-control {
        height: 50px;
    }
}

@media (max-width: 991.5px) {
    .contact-form {

        .col-md-6,
        .col-md-12 {
            padding: 0px;
        }

        .submit-form-btn button {
            width: 100%;
        }
    }
}

@media (min-width: 900.5px) and (max-width: 991.5px) {
    .footer-content .container .footer-section {
        justify-content: center;
        display: block;

        p {
            text-align: center;
        }

        ul {
            justify-content: center;
            display: flex;
            padding-left: 0px;

            li {
                text-align: center;
            }
        }
    }

    .footer-menu {
        text-align: center;
        justify-content: center;

        button {
            margin-bottom: 12px;
        }
    }

    .contact-form {
        width: 70%;
    }
}

@media (min-width: 769px) and (max-width: 899.5px) {
    .contact-form {
        width: 70% !important;
    }
}

@media (min-width: 280px) and (max-width: 899.5px) {
    .footer-menu {

        a,
        button {
            margin-right: 0px;
            margin-bottom: 12px;
        }
    }

    .footer-content .container .footer-section {
        justify-content: center;
        display: block;

        p {
            text-align: center;
        }

        ul {
            justify-content: center;
            display: block;
            padding-left: 0px;

            li {
                text-align: center;
                margin-right: 0px;
            }
        }
    }

    .footer-menu {
        display: grid;
        text-align: center;
        margin: 0px;

        button {
            margin-right: 0px;
            margin-bottom: 12px;
        }
    }

    .contact-form {
        width: 100%;
    }
}

@media (min-width: 280px) and (max-width: 768px) {
    .contact-form-rates {
        width: 100% !important;
    }
}