.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body #root[aria-hidden="true"] header {
  padding-right: 10px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

// #calendar {
//   span {
//     font-size: 20px!important;
//   }
// }
/*--Justify Content--*/
.jc-end {
  justify-content: flex-end;
}

/*--Container fluid--*/
@media only screen and (min-width: 1441px) and (max-width: 3000px) {
  .container-fluid {
    max-width: 1340px;
    margin: auto;
  }
}

/*--filters left side bar--*/
.icon-nav,
.icon-nav-active {
  margin-right: 10px;
  color: #fff;

  svg {
    width: 20px;
    height: 20px;
    position: relative;
    top: 8px;
  }

  span {
    margin: 5px;
    font-size: 15px;
  }

  &:hover,
  &:focus,
  &:active {
    color: #fff !important;
  }
}

/*--Left side--*/
.leftside-filter {
  .MuiPaper-root {
    width: 220px !important;
    background: var(--high-to-low);
    padding: 30px 15px;
  }

  h2 {
    font-size: 18px;
    margin-top: 0px;
    color: #fff;
    margin-bottom: 15px;

    svg {
      width: 19px;
      height: 18px;
      color: #fff;
      position: relative;
      top: 2px;
    }
  }
}

.left-filters-style {
  .dropdown-boxs {
    select {
      width: 100%;
      height: 30px;
      border-radius: 3px;
      margin-bottom: 13px;
      border: 0px;
      padding: 5px 5px;

      &:hover,
      &:focus,
      &:active {
        border: 0px;
        outline: 0px;
        box-shadow: 0px;
      }
    }
  }
}

.p-7-tb {
  padding: 7px 15px !important;
}

// left side menu manager child users class START
.leftside-filter-manager {
  .MuiPaper-root {
    width: 220px !important;
    background: var(--high-to-low);
    padding: 10px 12px;
  }

  hr {
    margin: 0px 0px 10px 0px;
  }

  .tabs-custom {
    .app-tabs .tabs-mui .MuiTabs-root button.MuiTab-root {
      padding: 6px 11.5px;
    }
  }

  h2 {
    font-size: 13px;
    margin-top: 0px;
    color: #fff;
    margin-bottom: 10px;

    svg {
      width: 16px;
      height: 16px;
      color: #fff;
      position: relative;
      top: 3px;
    }
  }
}

.left-filters-style-manager {
  .dropdown-boxs {
    select {
      width: 100%;
      height: 29px;
      border-radius: 3px;
      margin-bottom: 10px;
      border: 0px;
      padding: 5px 5px 5px 5px;
      font-size: 12px;

      &::placeholder {
        font-size: 16px;
      }

      &:hover,
      &:focus,
      &:active {
        border: 0px;
        outline: 0px;
        box-shadow: 0px;
      }
    }
  }
}

// left side menu manager child users class END
/*--Media Query--*/
@media (min-width: 280px) and (max-width: 1050px) {

  .icon-nav,
  .disply-none {
    display: block;
  }

  .filters-style {
    display: none;
  }

  .p-7-tb {
    padding: 7px 7px !important;
  }
}

@media (min-width: 1051px) and (max-width: 5000px) {

  .icon-nav,
  .disply-none {
    display: none;
  }
}